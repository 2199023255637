import React, { Component, useEffect, useState } from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";

import questions from "./questions";

const Profile = () => {
  // Basic info

  const completeCallback = (survey, options) => {
    console.log("Survey results: " + JSON.stringify(survey.data));
  };

  const model = new Survey.Model(questions);

  return <Survey.Survey model={model} onComplete={completeCallback} />;
};

export default Profile;
